// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".traitify--components-results-career-details-jobs-carousel---sectionHeading{font-size:24px;font-weight:600}.traitify--components-results-career-details-jobs-carousel---sectionHeading{margin-bottom:16px}.traitify--components-results-career-details-jobs-carousel---container{-webkit-box-align:stretch;-ms-flex-align:stretch;align-items:stretch;display:-webkit-box;display:-ms-flexbox;display:flex;gap:16px;margin-top:16px}.traitify--components-results-career-details-jobs-carousel---container .traitify--components-results-career-details-jobs-carousel---back,.traitify--components-results-career-details-jobs-carousel---container .traitify--components-results-career-details-jobs-carousel---next{-webkit-box-align:center;-ms-flex-align:center;align-items:center;display:-webkit-box;display:-ms-flexbox;display:flex;border:1px solid;border-radius:8px;padding:4px;border-color:#d6d6d6;border-color:var(--private-traitify-border);color:#25c9d0;color:var(--private-traitify-theme)}.traitify--components-results-career-details-jobs-carousel---container .traitify--components-results-career-details-jobs-carousel---back:disabled,.traitify--components-results-career-details-jobs-carousel---container .traitify--components-results-career-details-jobs-carousel---next:disabled{color:#d6d6d6;color:var(--private-traitify-inactive)}.traitify--components-results-career-details-jobs-carousel---content{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-flex:1;-ms-flex:1;flex:1;gap:16px;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;overflow-x:hidden}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sectionHeading": "traitify--components-results-career-details-jobs-carousel---sectionHeading",
	"container": "traitify--components-results-career-details-jobs-carousel---container",
	"back": "traitify--components-results-career-details-jobs-carousel---back",
	"next": "traitify--components-results-career-details-jobs-carousel---next",
	"content": "traitify--components-results-career-details-jobs-carousel---content"
};
export default ___CSS_LOADER_EXPORT___;

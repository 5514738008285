(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("react-dom"));
	else if(typeof define === 'function' && define.amd)
		define("Traitify", ["react", "react-dom"], factory);
	else if(typeof exports === 'object')
		exports["Traitify"] = factory(require("react"), require("react-dom"));
	else
		root["Traitify"] = factory(root["React"], root["ReactDOM"]);
})(this, function(__WEBPACK_EXTERNAL_MODULE__6422__, __WEBPACK_EXTERNAL_MODULE__6057__) {
return 
// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".traitify--components-results-personality-trait-details---sectionHeading{font-size:24px;font-weight:600}.traitify--components-results-personality-trait-details---sectionHeading{margin-bottom:16px}.traitify--components-results-personality-trait-details---bar{height:5px}.traitify--components-results-personality-trait-details---container{border-radius:16px;overflow:hidden;background:#f5f5f5;background:var(--private-traitify-background-light);margin-bottom:16px}@media(min-width: 960px){.traitify--components-results-personality-trait-details---container{margin-bottom:24px}}.traitify--components-results-personality-trait-details---container:last-child{margin-bottom:0}.traitify--components-results-personality-trait-details---content{-webkit-box-align:center;-ms-flex-align:center;align-items:center;display:-webkit-box;display:-ms-flexbox;display:flex;padding:8px 24px}.traitify--components-results-personality-trait-details---content img{width:50px;margin-right:16px}@media(min-width: 960px){.traitify--components-results-personality-trait-details---content img{margin-right:24px}}.traitify--components-results-personality-trait-details---description{display:block;font-weight:normal;margin-right:16px}@media(min-width: 568px){.traitify--components-results-personality-trait-details---description{display:inline-block;margin-left:8px}.traitify--components-results-personality-trait-details---description::before{content:\"-\";display:inline-block;margin-right:8px}}.traitify--components-results-personality-trait-details---heading{-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1;font-weight:bold}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sectionHeading": "traitify--components-results-personality-trait-details---sectionHeading",
	"bar": "traitify--components-results-personality-trait-details---bar",
	"container": "traitify--components-results-personality-trait-details---container",
	"content": "traitify--components-results-personality-trait-details---content",
	"description": "traitify--components-results-personality-trait-details---description",
	"heading": "traitify--components-results-personality-trait-details---heading"
};
export default ___CSS_LOADER_EXPORT___;
